import React from 'react';
import { Link } from "react-router-dom";
import CookieConsent from "react-cookie-consent";

import Navbar from "../components/homepage/navbar";
import HeroSection from '../components/homepage/section-top.js';
import BodySection from '../components/homepage/section-property.js';
import ComingSoon from '../components/homepage/comingsoon.js';
import Footer from "../components/homepage/footer.js";

function Home() {
  return (
    <div className="content-wrapper">
      <Navbar />
      <main>
        <HeroSection />
        <BodySection /> 
        <ComingSoon />
      </main>
      <Footer />
      <CookieConsentBanner />
    </div>
  );
}

function CookieConsentBanner() {
  return (
    <CookieConsent
      location="bottom"
      buttonText="Accept"
      cookieName="aisonCookie"
      expires={150}
      debug={false}
  
      acceptOnScroll={true}
      acceptOnScrollPercentage={20}
      style={{ background: "white" }}
      buttonStyle={{
        background: "#18AFD1",
        color: "white",
        fontSize: "16px",
        borderRadius: "12px",
        fontFamily: "neue-haas-grotesk-display",
        height: "24px"
      }}
    >
      <Link to="/Policy">By browsing this website you agree to our Cookie Policy. Learn More.</Link>
    </CookieConsent>
  );
}

export default Home;
